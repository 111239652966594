<template>
  <div>
    <messages-send-form />
  </div>
</template>

<script>
import MessagesSendForm from './MessagesSendForm.vue'

export default {
  components: {
    MessagesSendForm,
  },
}
</script>

<style>

</style>
