<template>
  <div>
    <b-card>
      <span class="pl-1 row justify-content-between">
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          쪽지 발송
        </h4>
      </span>
      <hr class="pb-1">
      <validation-observer ref="simpleRules">
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="6">
              <b-form-group
                class="font-small-3"
                label="사이트"
                label-for="messages-site"
                label-cols-md="4"
              >  <validation-provider
                #default="{ errors }"
                rules="required"
                mode="passive"
                name="사이트"
              >
                <b-form-select
                  v-if="authSite"
                  id="messages-site"
                  ref="messages-site"
                  v-model="siteSelected"
                  :options="authSite"
                  class="font-small-3"
                  size="sm"
                  style="color:rgb(229,166,48);"
                >
                  <template
                    v-if="false"
                    #first
                  >
                    <b-form-select-option :value="null">
                      선택
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger"> {{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="font-small-3"
                label="받는회원"
                label-for="messages-receiver"
                label-cols-md="4"
              >  <validation-provider
                #default="{ errors }"
                rules="required"
                mode="passive"
                name="받는회원"
              >
                <div @click="showModal()">
                  <b-input-group>
                    <b-form-input
                      id="messages-receiver"
                      v-model="receiversNicknames"
                      placeholder="클릭하여 유저검색"
                      class="cursor-pointer font-small-3 custom-placeholder-color"
                      readonly
                      style="color:rgb(229,166,48); opacity: 1;"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="SearchIcon"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <small class="text-danger"> {{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="font-small-3"
                label="쪽지발송 제목"
                label-for="messages-title"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|between:5,100"
                  mode="passive"
                  name="쪽지발송 제목"
                >
                  <b-form-input
                    id="messages-title"
                    v-model.lazy="messages.title"
                    class="font-small-3"
                    size="sm"
                    style="color:rgb(229,166,48)"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger"> {{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                class="font-small-3"
                label="쪽지내용"
                label-for="messages-message"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required|between:5,200"
                  mode="passive"
                  name="쪽지내용"
                >
                  <b-form-textarea
                    id="messages-message"
                    v-model="messages.message"
                    class="font-small-3"
                    size="sm"
                    placeholder=""
                    style="color:rgb(229,166,48)"
                    rows="4"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger"> {{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              class="mt-1"
              cols="12"
            >
              <b-button
                ref="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                size="sm"
                class="float-right"
                @click.prevent="btnSend"
              >
                발송
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                size="sm"
                class="float-right mr-1"
                @click.prevent="btnReset"
              >
                초기화
              </b-button>
              <b-button
                v-if="true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="float-left mr-1"
                @click.prevent="showModalMessageSendToMaster"
              >
                관리자 문의
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- Modal Form -->
    <messages-send-form-search-users
      ref="MessagesSendFormSearchUsers"
      modal-id="messages-search-form-search-users"
      :param-site.sync="siteSelected"
      :selected-receivers.sync="selectedReceivers"
      @changed="handleSiteChanged"
    />
    <!-- Modal Form -->
    <b-modal
      id="MessageSendToMaster"
      no-close-on-backdrop
      hide-footer
      centered
      @shown=""
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          관리자 문의
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="closeModalMessageSendToMaster"
          />
        </div>
      </template>
      <b-card no-body>
        <validation-observer ref="simpleRules">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="사이트"
                  label-for="messages-site"
                  label-cols-md="4"
                  label-align="left"
                >  <validation-provider
                  #default="{ errors }"
                  rules="required"
                  mode="passive"
                  name="사이트"
                >
                  <b-form-select
                    v-if="optionsSite"
                    id="messages-site"
                    ref="messages-site"
                    v-model="siteSelected"
                    :options="optionsSite"
                    class="font-small-3"
                    size="sm"
                    style="color:rgb(229,166,48) !important; opacity: 0.7;"
                  >
                    <template
                      v-if="false"
                      #first
                    >
                      <b-form-select-option :value="null">
                        선택
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small class="text-danger"> {{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="받는 회원"
                  label-for="messages-receiver"
                  label-cols-md="4"
                  label-align="left"
                >
                  <b-form-input
                    id="messages-receiver"
                    v-model="master"
                    class="font-small-3"
                    size="sm"
                    disabled
                    style="color:rgb(229,166,48)"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="쪽지 제목"
                  label-for="messages-title"
                  label-cols-md="4"
                  label-align="left"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|between:5,100"
                    mode="passive"
                    name="쪽지 제목"
                  >
                    <b-form-input
                      id="messages-title"
                      v-model.lazy="masterMessages.title"
                      class="font-small-3"
                      size="sm"
                      style="color:rgb(229,166,48)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger"> {{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="쪽지 내용"
                  label-for="messages-message"
                  label-cols-md="4"
                  label-align="left"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|between:5,200"
                    mode="passive"
                    name="쪽지내용"
                  >
                    <b-form-textarea
                      id="messages-message"
                      v-model="masterMessages.message"
                      class="font-small-3"
                      size="sm"
                      placeholder=""
                      style="color:rgb(229,166,48)"
                      rows="5"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger"> {{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                class="mt-1"
                cols="12"
              >
                <b-button
                  ref="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  size="sm"
                  class="float-right"
                  @click.prevent="btnSend('master')"
                >
                  발송
                </b-button>
                <b-button
                  ref="reset"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="reset"
                  variant="secondary"
                  size="sm"
                  class="float-right mr-2"
                  @click.prevent="closeModalMessageSendToMaster"
                >
                  취소
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BCard, BRow, BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormSelect, BFormSelectOption, BFormTextarea, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import {
  CREATE_MESSAGES,
} from '@/store/messages/mutation'
import { createNamespacedHelpers } from 'vuex'

import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

import validation from './_formValidation'
import fncShowSwal from './_vueSweetalert2'
import MessagesSendFormSearchUsers from './MessagesSendFormSearchUsers.vue'

const messagesStore = createNamespacedHelpers('messagesStore')

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BButton,

    ValidationObserver,
    ValidationProvider,

    MessagesSendFormSearchUsers,
  },
  directives: {
    Ripple,
  },
  props: {
    // modalId: {
    //   type: String,
    //   required: true,
    // },
    // toRole: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      siteSelected: localStorage.getItem('authSiteSelected'),
      paramSite: '',

      selectedReceivers: [],
      receiversNicknames: null,
      messages: {
        site: null,
        title: null,
        message: null,
        senderId: null,
        senderIp: null,
        receivers: [],
      },
      masterMessages: {
        site: null,
        title: null,
        message: null,
        senderId: null,
        senderIp: null,
        receivers: [],
      },

      master: 'master',
      // validations
      required,
      between,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      digits,
      length,
      alphaDash,
    }
  },
  computed: {
    optionsSite() {
      const authSite = localStorage.getItem('authSite').split(',')
      return authSite.map(site => ({ text: site, value: site }))
    },
    // ...messagesStore.mapGetters({
    //   sendMessages: 'sendMessages',
    // }),
    isRole() {
      return window.location.href.split('/').pop()
    },
  },
  watch: {
    // selectedSite: 'init',
    selectedReceivers: 'updateSelectedReceivers',
    siteSelected: {
      handler(event) {
        localStorage.setItem('authSiteSelected', event)
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  mounted() {
    console.log('MessagesSendForm mounted()')
    this.init()
  },
  methods: {
    ...messagesStore.mapActions({
      $createMessages: CREATE_MESSAGES,
    }),
    showModal() {
      this.$bvModal.show('MessagesSendFormSearchUsers') // 모달 ID를 사용해 모달을 엽니다
    },
    openModal() {
    },
    closeModal() {
      this.$bvModal.hide('MessagesSendFormSearchUsers')
    },
    showModalMessageSendToMaster() {
      this.$bvModal.show('MessageSendToMaster') // 모달 ID를 사용해 모달을 엽니다
    },
    closeModalMessageSendToMaster() {
      this.$bvModal.hide('MessageSendToMaster')
    },
    init() {
      this.messages = {
        site: this.siteSelected,
        title: null,
        message: null,
        senderId: 'admin',
        senderIp: localStorage.getItem('ip'),
        receivers: [],
      }
      this.masterMessages = {
        site: this.siteSelected,
        title: null,
        message: null,
        senderId: ['godfather'].includes(window.location.href.split('/').pop()) ? this.selectedSite : this.userData.userid,
        senderIp: localStorage.getItem('ip'),
        receivers: 'master',
      }
      this.selectedReceivers = []
    },
    updateSelectedReceivers() {
      const receiversNicknames = []
      const receiversUserIds = []

      this.selectedReceivers.forEach(item => {
        receiversNicknames.push(item.nickname)
        receiversUserIds.push(item.userid)
      })

      let receiversNicknamesMsg = ''

      if (receiversNicknames.length <= 5) {
        receiversNicknamesMsg = receiversNicknames.join(', ')
      } else {
        receiversNicknamesMsg = `${receiversNicknames.slice(0, 1).join(', ')}... 외 ${receiversNicknames.length - 1}건`
      }

      this.receiversNicknames = receiversNicknamesMsg
      this.messages.receivers = receiversUserIds
    },

    // fncModalMessagesSearchUsers() {
    //   this.$refs.messagesSearchUsers.$bvModal.show('messages-search-form-search-users')
    // },
    async btnSend(type) {
      if (!await validation(this.$refs.simpleRules)) return false
      // const result = await this.fncShowSwal('confirm')
      try {
        const result = await fncShowSwal('send')
        if (result.isConfirmed) {
          // console.log('messages \n', JSON.stringify(this.messages, null, 2))
          if (type === 'master') {
            await this.$createMessages({
              messages: {
                ...this.masterMessages,
              },
            }).then(async res => {
              if (res.status) {
                await fncShowSwal('success', '메세지 전송완료')
                await this.btnReset()
                await this.closeModalMessageSendToMaster()
              }
            })
          } else {
            await this.$createMessages({
              messages: {
                site: this.siteSelected,
                title: this.messages.title,
                message: this.messages.message,
                senderId: this.messages.senderId,
                senderIp: this.messages.senderIp,
                receivers: this.messages.receivers,
                // ...this.messages,
              },
            }).then(async res => {
              if (res.status) {
                await fncShowSwal('success', '메세지 전송완료')
                await this.btnReset()
              }
            })
          }
        }
      } catch (e) {
        console.error('[Messages] Send', e)
        await fncShowSwal('error', '메세지 전송실패!')
      }
      return null
    },
    async btnReset() {
      this.init()
    },
    handleSiteChanged(newSite) {
      console.log('@@ 부모에서 이벤트 처리: ', newSite)
      this.siteSelected = newSite
    },
  },
}
</script>

<style>
/* modal-width */
.modal-dialog {
    max-width: 100rem;
  }
/* disabled */
.dark-layout select.form-control:disabled, .dark-layout .custom-select:disabled {
    opacity: 1;
}
/* placeholder */
.custom-placeholder-color::placeholder {
  color: rgb(229, 166, 48) !important;
}
</style>
